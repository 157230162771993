<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
  <div>
    <!-- Authors Table -->
    <a-row :gutter="24" type="flex">
      <!-- Authors Table Column -->
      <a-col :span="24" class="mb-24">
        <!-- Authors Table Card -->
        <CardAuthorTable
          :data="table1Data"
          :columns="table1Columns"
          :getAllShops="getAllShops"
          :handelType="handelType"
          :loading="shoploading"
        ></CardAuthorTable>
        <!-- / Authors Table Card -->
      </a-col>
      <!-- Pagination -->
      <a-pagination
        :current="currentPage"
        :total="total"
        :pageSize="pageSize"
        @change="handlePageChange"
        class="pagination"
      />
      <!-- / Authors Table Column -->
    </a-row>
    <!-- / Authors Table -->

    <!-- Projects Table -->
    <!-- <a-row :gutter="24" type="flex">

			<a-col :span="24" class="mb-24">
				<CardProjectTable2
					:data="table2Data"
					:columns="table2Columns"
				></CardProjectTable2>

			</a-col>
		</a-row> -->
    <!-- / Projects Table -->
  </div>
</template>

<script>
import { sellerlist } from "@/api/shop";
// "Authors" table component.
import CardAuthorTable from "../components/Cards/CardAuthorTable";

// "Projects" table component.
import CardProjectTable2 from "../components/Cards/CardProjectTable2";

// "Authors" table list of columns and their properties.
const table1Columns = [
  {
    title: "账号",
    dataIndex: "shop",
    scopedSlots: { customRender: "shop" },
  },
    {
    title: "名称",
    dataIndex: "shop",
    scopedSlots: { customRender: "company" },
  },
  {
    title: "网址",
    dataIndex: "website",
    scopedSlots: { customRender: "website" },
  },
  {
    title: "状态",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "有效期",
    dataIndex: "expire",
    class: "text-muted",
  },
  {
    title: "操作",
    scopedSlots: { customRender: "editBtn" },
    width: 50,
  },
];

// "Projects" table list of columns and their properties.
const table2Columns = [
  {
    title: "COMPANIES",
    dataIndex: "company",
    scopedSlots: { customRender: "company" },
    width: 300,
  },
  {
    title: "BUDGET",
    dataIndex: "budget",
    class: "font-semibold text-muted",
  },
  {
    title: "STATUS",
    dataIndex: "status",
    class: "font-semibold text-muted text-sm",
  },
  {
    title: "COMPLETION",
    scopedSlots: { customRender: "completion" },
    dataIndex: "completion",
  },
  {
    title: "",
    scopedSlots: { customRender: "editBtn" },
    width: 50,
  },
];

// "Projects" table list of rows and their properties.
const table2Data = [
  {
    key: "1",
    company: {
      name: "Spotify Version",
      logo: "images/logos/logo-spotify.svg",
    },
    status: "working",
    budget: "$14,000",
    completion: 60,
  },
  {
    key: "2",
    company: {
      name: "Progress Track",
      logo: "images/logos/logo-atlassian.svg",
    },
    status: "working",
    budget: "$3,000",
    completion: 10,
  },
  {
    key: "3",
    company: {
      name: "Jira Platform Errors",
      logo: "images/logos/logo-slack.svg",
    },
    status: "done",
    budget: "Not Set",
    completion: {
      status: "success",
      value: 100,
    },
  },
  {
    key: "4",
    company: {
      name: "Launch new Mobile App",
      logo: "images/logos/logo-spotify.svg",
    },
    status: "canceled",
    budget: "$20,600",
    completion: {
      status: "exception",
      value: 50,
    },
  },
  {
    key: "5",
    company: {
      name: "Web Dev",
      logo: "images/logos/logo-webdev.svg",
    },
    status: "working",
    budget: "$4,000",
    completion: 80,
  },
  {
    key: "6",
    company: {
      name: "Redesign Online Store",
      logo: "images/logos/logo-invision.svg",
    },
    status: "canceled",
    budget: "$2,000",
    completion: {
      status: "exception",
      value: 0,
    },
  },
];

export default {
  components: {
    CardAuthorTable,
    CardProjectTable2,
  },
  data() {
    return {
      // Associating "Authors" table data with its corresponding property.
      table1Data: [],

      // Associating "Authors" table columns with its corresponding property.
      table1Columns: table1Columns,

      // Associating "Projects" table data with its corresponding property.
      table2Data: table2Data,

      // Associating "Projects" table columns with its corresponding property.
      table2Columns: table2Columns,
      currentPage: 1,
      pageSize: 10,
      total: "",
      shoploading: false,
      type: "all",
    };
  },
  methods: {
    handelType(type) {
      this.type = type;
      this.currentPage = 1;
    },
    getAllShops(type) {
      this.shoploading = true;
      sellerlist(this.currentPage, this.pageSize, type).then((res) => {
        this.table1Data = res.data.list;
        this.total = res.data.pagination.total;
        this.shoploading = false;
      });
    },
    // Handle page change
    handlePageChange(page) {
      this.currentPage = page;
      this.getAllShops(this.type);
    },
  },
  created() {
    this.getAllShops();
  },
};
</script>

<style lang="scss">
</style>