import client from "./internal/httpClient";

export function shoplist(type) {
  return client.post("/seller/shop-list",{type:type});
}
export function addshop(name, email, password,company,expiretime) {
  return client.post("/seller/register", {
    name: name,
    email: email,
    password: password,
    company:company,
    expiretime: expiretime,
  });
}
export function udpateshop(id, expiretime) {
  return client.post("/seller/update-shop", {
    id: id,
    expiretime: expiretime,
  });
}
export function deleteshop(id, name) {
  return client.post("/seller/delete-shop", {
    id: id,
    name: name,
  });
}
export function sellerlist(page, pagesize, type) {
  return client.post("/seller/list", {
    page: page,
    pagesize: pagesize,
    type: type
  });
}
export function logisticlist(data) {
  return client.post("/seller/logistics-list", data);
}
export function logout() {
  return client.post("/api/v1/auth/logout", {});
}


