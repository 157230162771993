<template>
  <!-- Authors Table Card -->
  <div>
    <a-card
      :bordered="false"
      class="header-solid h-full"
      :bodyStyle="{ padding: 0 }"
    >
      <template #title>
        <a-row type="flex" align="middle">
          <a-col :span="24" :md="12">
            <h5 class="font-semibold m-0">店铺管理</h5>
          </a-col>
          <a-col
            :span="24"
            :md="12"
            style="
              display: flex;
              align-items: center;
              justify-content: flex-end;
            "
          >
            <a-button
              type="primary"
              class="add-shop"
              @click="() => (modal2Visible = true)"
              size="small"
            >
              新增店铺
            </a-button>
            <a-radio-group
              v-model="authorsHeaderBtns"
              size="small"
              @change="ChangeShopType"
            >
              <a-radio-button value="all">所有</a-radio-button>
              <a-radio-button value="active">有效</a-radio-button>
              <a-radio-button value="unactive">无效</a-radio-button>
            </a-radio-group>
          </a-col>
        </a-row>
      </template>
      
      <a-table :columns="columns" :data-source="data" :pagination="false">
        <template slot="shop" slot-scope="shop">
          <div class="table-avatar-info">
            <a-avatar shape="square" :src="shop.avatar" />
            <div class="avatar-info">
              <h6>{{ shop.name }}</h6>
              <p>{{ shop.email }}</p>
            </div>
          </div>
        </template>
        <template slot="company" slot-scope="shop">
          <div class="author-info">
            <h6 class="m-0">{{ shop.company }}</h6>
          </div>
        </template>
        <template slot="website" slot-scope="website">
          <div class="author-info">
            <h6 class="m-0">{{ website }}</h6>
          </div>
        </template>

        <template slot="status" slot-scope="status">
          <a-tag
            class="tag-status"
            :class="status ? 'ant-tag-primary' : 'ant-tag-muted'"
          >
            {{ status ? "有效" : "失效" }}
          </a-tag>
        </template>

        <template slot="editBtn" slot-scope="row">
          <div style="white-space: pre">
            <a-button
              type="link"
              :data-id="row.key"
              class="btn-edit"
              v-if="row.status"
              @click="loginAdmin(row)"
            >
              进入后台
            </a-button>
            <a-button
              type="link"
              :data-id="row.key"
              class="btn-edit"
              @click="openEditShop(row)"
            >
              编辑
            </a-button>
            <a-popconfirm
              title="你确定要回收这个店铺吗？删除后不可恢复！"
              ok-text="是的"
              cancel-text="取消"
              @confirm="deleteShop(row)"
            >
              <a-button type="link" :data-id="row.key" class="btn-delete">
                删除
              </a-button>
            </a-popconfirm>
          </div>
        </template>
      </a-table>
    </a-card>
    <a-modal
      v-model="modal2Visible"
      :title="modelTitle"
      cancelText="取消"
      okText="确认"
      centered
      @cancel="handleCancelModel"
      @ok="handleAddShop"
    >
      <a-form-model ref="form" :model="form" :rules="rules">
        <a-form-model-item label="店铺名称" prop="name">
          <a-input-search
            placeholder="输入店铺名称"
            size="large"
            v-model="form.name"
            :disabled="modelType === 'edit'"
            @change="handleInputChange"
          >
            <a-button slot="enterButton" disabled>.{{APP_URL}}</a-button>
          </a-input-search>
          <!-- <a-select
            style="width: 100%"
            placeholder="店铺名称"
            @change="handleChange"
            show-search
            option-filter-prop="children"
            v-model="form.name"
            :disabled="modelType === 'edit'"
          >
            <a-select-option
              v-for="item in shopNameList"
              :key="item.id"
              :value="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select> -->
        </a-form-model-item>
        <a-form-model-item ref="name" label="公司名称" prop="company">
          <a-input
            v-model="form.company"
            :disabled="modelType === 'edit'"
            @blur="
              () => {
                $refs.name.onFieldBlur();
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item ref="name" label="店铺密码" prop="password">
          <a-input
            v-model="form.password"
            :disabled="modelType === 'edit'"
            @blur="
              () => {
                $refs.name.onFieldBlur();
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item label="到期时间" required prop="date1">
          <a-date-picker
            v-model="form.date1"
            show-time
            type="date"
            placeholder="选择到期时间"
            style="width: 100%"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>

  <!-- / Authors Table Card -->
</template>

<script>
import { shoplist, addshop, udpateshop, deleteshop } from "@/api/shop";
import { loginadmin } from "@/api/login";
import { setShopToken } from "@/utils/index";
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    handelType: {
      type: Function,
      required: true,
    },
    getAllShops: {
      type: Function,
      required: true,
    },
  },
  created() {
    this.getAllShopNames();
  },
  data() {
    return {
      // Active button for the "Authors" table's card header radio button group.
      authorsHeaderBtns: "all",
      APP_URL:process.env.APP_URL,
      modal2Visible: false,
      modelType: "add",
      shopNameList: [],
      modelTitle: "新增店铺",
      currentShopId: 0,
      form: {
        name: "",
        date1: undefined,
        password: "",
        company: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入店铺名称",
            trigger: "blur",
          },
          { validator: this.validateName, trigger: "blur" },
        ],
        password: [
          {
            required: true,
            message: "请输入店铺密码",
            trigger: "blur",
          },
        ],
        date1: [
          { required: true, message: "请选择到期时间", trigger: "change" },
        ],
      },
    };
  },
  computed: {
    // Compute the data to display based on current page and page size
    displayData() {
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.data.slice(startIndex, endIndex);
    },
  },
  methods: {
    validateName(rule, value, callback) {
      if (!/^[a-zA-Z]*$/.test(value)) {
        callback(new Error("请输入英文字母"));
      } else {
        callback();
      }
    },
    handleInputChange(value) {
      // 使用正则表达式检查输入值是否只包含英文字母
      if (!/^[a-zA-Z]*$/.test(value)) {
        // 如果输入值包含非英文字母字符，则移除非英文字母字符
        this.searchText = value.replace(/[^a-zA-Z]/g, "");
      }
    },
    handleCancelModel() {
      this.form.name = "";
      this.form.date1 = "";
      this.form.password = "";
      this.form.company = "";
      this.setModal1Visible(false);
      this.modelType = "add";
    },
    setModal1Visible(visiable){
      this.modal2Visible = visiable
    },
    ChangeShopType(e) {
      this.authorsHeaderBtns = e.target.value;
      this.handelType(e.target.value);
      this.getAllShops(this.authorsHeaderBtns);
    },
    deleteShop(e) {
      deleteshop(parseInt(e.key), e.shop.name).then((res) => {
        this.$message.success("删除成功！");
        this.getAllShops(this.authorsHeaderBtns);
      });
    },
    openEditShop(row) {
      this.modelTitle = "修改店铺";
      this.modelType = "edit";
      this.form.name = row.shop.name;
      this.form.date1 = row.expire;
      this.form.company = row.shop.company;
      this.form.password = "******";
      this.setModal1Visible(true);
      this.currentShopId = parseInt(row.key);
    },
    handleAddShop() {
      this.$refs.form.validate((valid) => {
        if (valid && this.form.date1 != "") {
          const dateString = this.form.date1;
          // 使用Date对象进行日期格式化
          const dateObject = new Date(dateString);
          const year = dateObject.getFullYear();
          const month = dateObject.getMonth() + 1; // 月份从0开始，需要+1
          const date = dateObject.getDate();
          const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${
            date < 10 ? "0" + date : date
          }`;
          const website = this.form.name + "."+process.env.APP_URL+"/admin";
          const account = this.form.name + "@"+process.env.APP_URL;
          const passwd = this.form.password;
          if (this.modelType == "add") {
            addshop(
              this.form.name,
              `${this.form.name}@${process.env.APP_URL}`,
              this.form.password,
              this.form.company != "" ? this.form.company : this.form.name,
              formattedDate,
              this.modelType
            ).then((res) => {
              this.$message.success("注册成功！");
              console.log(website, account, passwd);
              navigator.clipboard
                .writeText(
                  `登录网址:${website}---登录账号:${account}---登录密码:${passwd}`
                )
                .then(() => {
                  console.log(website, account, passwd);
                  this.$message.warning(
                    "账密信息已经复制到剪切板且只展示一次 请谨慎使用！"
                  );
                })
                .catch((err) => {
                  this.$message.error("抱歉，注册出错！");
                });
            });
          } else {
            udpateshop(this.currentShopId, formattedDate).then((res) => {
              this.$message.success("修改成功！");
            });
          }
          this.setModal1Visible(false);
          this.form.name = "";
          this.form.date1 = undefined;
          this.form.password = "";
          this.form.company = "";
          // 调用父组件的方法刷新数据
          this.getAllShops(this.authorsHeaderBtns);
        } else {
          this.$message.warning("请检查表单！");
        }
      });
    },
    loginAdmin(row) {
      loginadmin(row.shop.name, row.website.replace("/admin", "")).then(
        (res) => {
          setShopToken(res.data.cookieName, res.data.cookieValue);
          this.$message.success("正在进入后台！请稍后");
          setTimeout(() => {
            window.open("https://" + row.website);
          }, 1000);
        }
      );
    },
  },
};
</script>
